@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Abel&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #202020;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #FFD700;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.abel {
  font-family: "Abel", sans-serif;
  font-weight: 400;
  font-style: normal;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.playfair {
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-style: normal;
}
.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25D366; /* WhatsApp Green */
  color: white;
  border: none;
  border-radius: 50%;
  padding: 15px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* Make sure it's above other elements */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
}

.whatsapp-button:hover {
  transform: scale(1.1); /* Slightly enlarge the button on hover */
}

.whatsapp-button svg {
  color: white;
}
